export default {
  rasta: {
    97: '0x19f6746BCeb90D625A2E24b5fDA820cA3B994260',
    56: '0xe3e8cc42da487d1116d26687856e9fb684817c52',
  },
  syrup: {
    97: '0x7f470100c48295895Ef02f0Faa61Ba94Ce8b6E21',
    56: '0xd24025bFD89b9C4cD01AB910BA506b65BaEeFfFe',
  },
  masterChef: {
    97: '0x35b1EF3FF9763F72C11e9f08B471D0b6b5A7515D',
    56: '0xec89Be665c851FfBAe2a8Ded03080F3E64116539',
  },
  airNft: {
    97: '0x50751588924b480f9e9d4A1541CCa83f75aC0ECC',
    56: '0xF5db804101d8600c26598A1Ba465166c33CdAA4b',
  },
  zionlionsNFT: {
    97: '0xF71cff2D42D02De425A37180A33d65d9aE619621',
    56: '0x8492d8e17f3e520e171682d792b0eb79dc126b4e',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lottery: {
    97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
    56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
  },
  lotteryNFT: {
    97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
    56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x35be5ca7917442ed5d2d4cc56ec9988dd158513e',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  },
  busd: {
    56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    97: '',
  },
  btcb: {
    56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059ff775485246999027b3197955',
    97: '',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  dai: {
    56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    97: '',
  },
  dot: {
    56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    97: '',
  },
  cake: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '',
  },
  crox: {
    56: '0x2c094f5a7d1146bb93850f629501eb749f6ed491',
    97: '',
  },
  mrasta: {
    56: '0xeaa4a2469a8471bd8314b2ff63c1d113fe8114ba',
    97: '0x19f6746BCeb90D625A2E24b5fDA820cA3B994260',
  },
  cns: {
    56: '0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63',
    97: '',
  },
  cnr: {
    56: '0xdCbA3fbd7BBc28abD18A472D28358089467A8a74',
    97: '',
  },
  dfl: {
    56: '0xd675ff2b0ff139e14f86d87b7a6049ca7c66d76e',
    97: '',
  },
  latte: {
    56: '0xa269A9942086f5F87930499dC8317ccC9dF2b6CB',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tokenConverter: {
    56: '0x5bE111400301Db3C31d31CB1A9D6d24eAA4315Be',
    97: '0x266D307A4c24297A9652753eA98729F534511cA2',
  },
  zion: {
    56: '0x1E5AcF2b8f7886a69850d13bE861Efdb26D2C0A6',
    97: '0x8ec4a1e5d2C8118Ca7860340d108747a0a30afCF',
  },
  sound: {
    56: '0xB50B04Ed85FfdCeB61BFe2b44004FdfF87586C1A',
    97: '0x8ec4a1e5d2C8118Ca7860340d108747a0a30afCF',
  },
}
