import { getCakeAddress } from 'utils/addressHelpers'

const nftPools = [
  {
    id: 1,
    poolName: 'Explorer Adventure Pool',
    description: 'Stake 10 Explorers to supercharge your $ZION staking.',
    rewardTokenAddress: getCakeAddress(),
    rewardTokenSymbol: 'ZION',
    nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
    contractAddress: {
      97: '0x18aaE5ee7959903F30163F60640d2573505aB274',
      56: '0x74cD1A0BDC2bF93F6728D41cC667abDd25285F97',
    },
    rewardRate: '11574000000000000',
    projectLink: '/',
    type: 'zion-lion',
    subType: 'explorer-adventure',
    icon: 'explorer.png',
    harvest: true,
    isFinished: false,
    tokenDecimals: 18,
    maxDepositAmount: '30000000000000000000000',
    depositFee: 0,
    ribbon: true,
    ribbonText: 'New',
  },
  {
    id: 2,
    poolName: 'Builders Lock',
    rewardTokenAddress: getCakeAddress(),
    rewardTokenSymbol: 'ZION',
    nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
    contractAddress: {
      97: '0x73e77BbFe9fF570A391828A4644a68838087396f',
      56: '0x304a8a1552504Dd84C8B80d3DE7fA7657F3B4C64',
    },
    rewardRate: '11574000000000000',
    projectLink: '/',
    type: 'zion-lion',
    subType: 'builder',
    icon: 'builder_lock.jpg',
    harvest: true,
    isFinished: false,
    tokenDecimals: 18,
    depositFee: 0,
    ribbon: true,
    ribbonText: 'New',
  },
  {
    id: 3,
    poolName: 'Explorers Lock',
    rewardTokenAddress: getCakeAddress(),
    rewardTokenSymbol: 'ZION',
    nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
    contractAddress: {
      97: '0xFF32F072a74356f898D8BBaEce356E5036BC6316',
      56: '0xB0889EA035a31756da184aA92853fb317BaCBF02',
    },
    rewardRate: '11574000000000000',
    projectLink: '/',
    type: 'zion-lion',
    subType: 'explorer',
    icon: 'explorer_lock.jfif',
    harvest: true,
    isFinished: false,
    tokenDecimals: 18,
    depositFee: 0,
    ribbon: true,
    ribbonText: 'New',
  },
  {
    id: 4,
    poolName: 'Farmers Lock',
    rewardTokenAddress: getCakeAddress(),
    rewardTokenSymbol: 'ZION',
    nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
    contractAddress: {
      97: '0x37Ec25b90C5A5828624ff56F7C0c919AD8C5FDa5',
      56: '0x8E7C956917C5a16414Cb7ddf4A035589C4fCB545',
    },
    rewardRate: '11574000000000000',
    projectLink: '/',
    type: 'zion-lion',
    subType: 'farmer',
    icon: 'farmers.jpg',
    harvest: true,
    isFinished: false,
    tokenDecimals: 18,
    depositFee: 0,
    ribbon: true,
    ribbonText: 'New',
  },
  // {
  //   id: 234,
  //   poolName: 'RastaDividend AirNFT',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0xF5db804101d8600c26598A1Ba465166c33CdAA4b',
  //   contractAddress: {
  //     97: '',
  //     56: '0xcc406fdA6ea668ca89C0F7a6c70658a875Af082C',
  //   },
  //   rewardRate: '100000000000000',
  //   projectLink: 'https://app.airnfts.com/creators/0x21C8B8069f7B9950cbdA2EF4Af12Aa98c9D97A61',
  //   type: 'airnft',
  //   icon: 'airnft.svg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   ribbon: false,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 123,
  //   poolName: 'StreetPunks NFT',
  //   rewardTokenAddress: '0x3ca35c4f8bc33D44d10d379F3fE601bA67bBbE7f',
  //   rewardTokenSymbol: 'PUNKS',
  //   nftContractAddress: '0xB6b545Ac637aec4EC6599258F7CDe70261676333',
  //   contractAddress: {
  //     97: '',
  //     56: '0x2709f5D9a35d89B2Ca7930bb10165300F169E42E',
  //   },
  //   rewardRate: '93339307048984470000000',
  //   projectLink: 'https://tofunft.com/collection/street-punks/items',
  //   type: 'spnft',
  //   icon: 'streetpunk.png',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   ribbon: false,
  //   ribbonText: 'New',
  // },
  // {
  //   id: 10,
  //   poolName: 'Explorer Training Academy Pool',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x319c3E6B3DE1bFE196C6e05A0b4e20Cf68116Ee1',
  //     56: '0xe4ad0837Bb5C95654BFFF4025632878c7Adc84bD',
  //   },
  //   rewardRate: '2300000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'explorer',
  //   icon: 'explorer_lock.jfif',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 0,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 11,
  //   poolName: 'Builders Construction Pool',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x6fBE356a7bA3dDA8Ce102b2C73433775b7f1f300',
  //     56: '0x0A51aB5079AD68006e05d5e64623FC21a6BF5539',
  //   },
  //   rewardRate: '2300000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'builder',
  //   icon: 'builder_lock.jpg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 0,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 6,
  //   poolName: 'ZionFarmers',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x319c3E6B3DE1bFE196C6e05A0b4e20Cf68116Ee1',
  //     56: '0x2017d344Aa627AEBD55Cd63E458c1D01eCFd78FF',
  //   },
  //   rewardRate: '11574000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'farmer',
  //   icon: 'farmers.jpg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 0,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 6,
  //   poolName: 'ZionFarmers',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x319c3E6B3DE1bFE196C6e05A0b4e20Cf68116Ee1',
  //     56: '0x2017d344Aa627AEBD55Cd63E458c1D01eCFd78FF',
  //   },
  //   rewardRate: '11574000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'farmer',
  //   icon: 'farmers.jpg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 0,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 7,
  //   poolName: 'ZionBuilders',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '',
  //     56: '0x0D05102B47beE77c55391247ec340D8Bc527F903',
  //   },
  //   rewardRate: '3858000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'builder',
  //   icon: 'zionlions.jpg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 3,
  //   poolName: 'ZionBuilders',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '',
  //     56: '0xF5475159CeE27693ad975286Ab51848afA332E27',
  //   },
  //   rewardRate: '3858000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'builder',
  //   icon: 'zionlions.jpg',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 8,
  //   poolName: 'Explorer Adventure Pool',
  //   description: 'Stake 10 Explorers to supercharge your $RASTA staking.',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x6918a4048F3022e5E07BDE354c1d130Eb01Ed25A',
  //     56: '0x7218e1a60C6E8D8c92147aedA2f0B18800BC9b2E',
  //   },
  //   rewardRate: '11574000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'explorer-adventure',
  //   icon: 'explorer.png',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 0,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
  // {
  //   id: 5,
  //   poolName: 'Explorer Adventure Pool',
  //   description: 'Stake 10 Explorers to supercharge your $RASTA staking.',
  //   rewardTokenAddress: getCakeAddress(),
  //   rewardTokenSymbol: 'RASTA',
  //   nftContractAddress: '0x8492D8E17F3e520e171682D792B0eb79dC126B4E',
  //   contractAddress: {
  //     97: '0x5Ca83C5D9E5D11886E20eAc166F0314faA75617C',
  //     56: '0xa29EF297aB70455B5262d23EfDD1519F06eB03c9',
  //   },
  //   rewardRate: '11574000000000000',
  //   projectLink: '/',
  //   type: 'zlnft',
  //   subType: 'explorer-adventure',
  //   icon: 'explorer.png',
  //   harvest: true,
  //   isFinished: true,
  //   tokenDecimals: 18,
  //   maxDepositAmount: '25000000000000000000000',
  //   depositFee: 3,
  //   ribbon: true,
  //   ribbonText: 'Retired',
  // },
]

export const RastaNftIds = [
  222912, 222905, 221411, 221414, 222910, 222906, 222914, 222908, 222915, 222918, 222916, 222907, 221401, 221406,
  221418, 221417, 221404, 221407, 221413, 221402, 221371, 221369, 221366, 221373, 221375, 221374, 221382, 221385,
  221370, 221378, 241086, 241043, 241047, 241049, 241054, 241057, 241060, 241080, 241064, 241106, 243117, 243102,
  243104, 243105, 243106, 243116, 243115, 243113, 243111, 243110, 245257, 248313, 248302, 248303, 248307, 248310,
  248312, 248309, 248306, 248305, 248311, 251901, 251900, 251907, 251906, 251905, 251904, 251903, 251902, 251899,
  251898, 263164, 263165, 263166, 263167, 263168, 263169, 263170, 263171, 263172, 263175, 272551, 272550, 272552,
]

export const ZionLionsNftIDs = [
  88, 93, 94, 102, 105, 106, 108, 120, 122, 147, 155, 156, 166, 177, 178, 180, 181, 188, 191, 196, 204, 214, 215, 217,
  226, 227, 268, 279, 282, 290, 297, 298, 304, 333, 342, 345, 349, 359, 368, 383, 400, 402, 405, 410, 411, 418, 428,
  452, 462, 466, 475, 482, 484, 485, 494, 496, 498, 499, 508, 519, 524, 526, 530, 532, 548, 554, 556, 566, 568, 574,
  580, 581, 591, 600, 616, 619, 623, 631, 636, 637, 650, 651, 665, 667, 668, 670, 682, 686, 695, 698, 700, 701, 707,
  712, 725, 728, 729, 762, 767, 775, 777, 784, 785, 786, 798, 802, 809, 813, 814, 824, 829, 831, 841, 845, 847, 853,
  862, 864, 866, 868, 887, 899, 905, 908, 909, 915, 931, 933, 937, 939, 940, 952, 955, 959, 967, 973, 975, 977, 982,
  990, 992, 1002, 1006, 1009, 1014, 1016, 1019, 1022, 1024, 1036, 1046, 1052, 1062, 1069, 1071, 1072, 1079, 1085, 1103,
  1109, 1120, 1122, 1125, 1131, 1138, 1139, 1140, 1147, 1154, 1171, 1182, 1186, 1195, 1196, 1209, 1212, 1217, 1220,
  1226, 1231, 1232, 1233, 1238, 1242, 1244, 1246, 1248, 1260, 1265, 1269, 1273, 1276, 1279, 1290, 1296, 1301, 1310,
  1311, 1314, 1318, 1325, 1330, 1343, 1344, 1358, 1360, 1375, 1376, 1388, 1389, 1392, 1398, 1399, 1400, 1403, 1409,
  1413, 1417, 1424, 1425, 1431, 1433, 1434, 1436, 1439, 1448, 1452, 1462, 1463, 1466, 1474, 1482, 1491, 1495, 1498,
  1506, 1508, 1513, 1514, 1515, 1517, 1530, 1533, 1537, 1549, 1555, 1556, 1566, 1568, 1572, 1577, 1578, 1586, 1594,
  1596, 1603, 1604, 1617, 1629, 1633, 1637, 1639, 1640, 1647, 1664, 1671, 1681, 1683, 1684, 1689, 1701, 1704, 1708,
  1713, 1714, 1717, 1722, 1728, 1740, 1752, 1757, 1759, 1760, 1769, 1780, 1784, 1785, 1790, 1792, 1800, 1832, 1835,
  1841, 1848, 1852, 1863, 1866, 1889, 1900, 1903, 1906, 1910, 1911, 1924, 1926, 1937, 1942, 1944, 1948, 1950, 1952,
  1958, 1964, 1965, 1972, 1986, 1990, 1991, 1996, 1997, 1998, 2000, 2003, 2007, 2009, 2012, 2014, 2015, 2020, 2023,
  2029, 2036, 2038, 2046, 2054, 2055, 2059, 2060, 2066, 2071, 2076, 2079, 2080, 2081, 2082, 2083, 2084, 2092, 2098,
  2102, 2104, 2108, 2113, 2114, 2115, 2120, 2121, 2125, 2127, 2129, 2149, 2151, 2161, 2171, 2185, 2188, 2189, 2192,
  2196, 2202, 2207, 2213, 2214, 2216, 2223, 2235, 2238, 2239, 2244, 2245, 2249, 2251, 2253, 2275, 2276, 2278, 2280,
  2281, 2287, 2288, 2302, 2303, 2307, 2308, 2310, 2313, 2318, 2324, 2334, 2338, 2368, 2381, 2386, 2391, 2394, 2395,
  2399, 2406, 2412, 2418, 2419, 2444, 2446, 2447, 2457, 2460, 2463, 2473, 2477, 2490, 2492, 2495, 2500, 2501, 2503,
  2507, 2517, 2519, 2525, 2529, 2538, 2540, 2541, 2551, 2559, 2563, 2564, 2570, 2574, 2575, 2578, 2582, 2601, 2608,
  2609, 2618, 2622, 2627, 2629, 2631, 2643, 2655, 2663, 2668, 2673, 2676, 2685, 2689, 2690, 2699, 2700, 2706, 2708,
  2710, 2713, 2748, 2749, 2753, 2763, 2777, 2782, 2795, 2806, 2811, 2813, 2814, 2822, 2824, 2825, 2826, 2835, 2838,
  2848, 2870, 2874, 2875, 2878, 2889, 2895, 2898, 2900, 2902, 2909, 2923, 2925, 2930, 2950, 2979, 2980, 2987, 2991,
  3004, 3006, 3008, 3009, 3010, 3018, 3024, 3034, 3040, 3044, 3045, 3048, 3053, 3056, 3057, 3067, 3070, 3071, 3081,
  3091, 3092, 3093, 3095, 3100, 3114, 3124, 3130, 3131, 3137, 3139, 3141, 3148, 3154, 3157, 3161, 3174, 3176, 3190,
  3193, 3205, 3206, 3212, 3215, 3217, 3218, 3219, 3250, 3258, 3259, 3261, 3267, 3273, 3278, 3286, 3293, 3298, 3303,
  3311, 3320, 3332, 3339, 3342, 3348, 3350, 3354, 3364, 3366, 3369, 3373, 3391, 3393, 3396, 3403, 3412, 3414, 3421,
  3425, 3428, 3439, 3442, 3443, 3453, 3456, 3458, 3462, 3463, 3464, 3474, 3485, 3491, 3503, 3505, 3511, 3515, 3516,
  3517, 3521, 3523, 3525, 3536, 3541, 3545, 3559, 3562, 3568, 3579, 3593, 3601, 3603, 3605, 3610, 3614, 3619, 3620,
  3622, 3624, 3628, 3639, 3640, 3641, 3644, 3649, 3653, 3667, 3670, 3686, 3691, 3692, 3694, 3706, 3711, 3713, 3717,
  3726, 3727, 3736, 3741, 3744, 3748, 3755, 3762, 3763, 3766, 3770, 3789, 3790, 3794, 3796, 3798, 3803, 3808, 3814,
  3821, 3831, 3833, 3834, 3839, 3855, 3862, 3863, 3864, 3865, 3873, 3878, 3883, 3884, 3891, 3899, 3903, 3909, 3915,
  3916, 3919, 3932, 3934, 3935, 3939, 3941, 3947, 3958, 3959, 3967, 3969, 3977, 3984, 3986, 3992, 4002, 4009, 4011,
  4013, 4020, 4028, 4032, 4038, 4044, 4052, 4054, 4056, 4059, 4062, 4068, 4076, 4084, 4095, 4098, 4099, 4102, 4103,
  4105, 4129, 4152, 4155, 4167, 4170, 4171, 4172, 4175, 4177, 4181, 4191, 4196, 4200, 4214, 4223, 4226, 4231, 4238,
  4239, 4240, 4244, 4257, 4259, 4261, 4263, 4266, 4268, 4276, 4282, 4283, 4289, 4297, 4307, 4308, 4317, 4321, 4327,
  4334, 4337, 4343, 4349, 4355, 4356, 4358, 4371, 4373, 4375, 4376, 4377, 4380, 4385, 4403, 4408, 4419, 4426, 4430,
  4433, 4436, 4438, 4439, 4445, 4454, 4460, 4461, 4471, 4485, 4489, 4490, 4493, 4496, 4501, 4507, 4512, 4513, 4514,
  4515, 4521, 4527, 4537, 4553, 4574, 4578, 4580, 4581, 4586, 4597, 4618, 4619, 4624, 4632, 4639, 4645, 4648, 4649,
  4655, 4656, 4657, 4667, 4669, 4672, 4682, 4690, 4704, 4706, 4721, 4724, 4727, 4739, 4740, 4741, 4749, 4758, 4764,
  4767, 4770, 4773, 4779, 4799, 4812, 4817, 4828, 4834, 4835, 4844, 4857, 4866, 4868, 4870, 4872, 4875, 4880, 4886,
  4906, 4914, 4915, 4926, 4930, 4932, 4934, 4944, 4948, 4953, 4954, 4958, 4961, 4962, 4963, 4969, 4973, 4974, 4982,
  4983, 4985, 4993, 5016, 5028, 5033, 5038, 5060, 5063, 5074, 5087, 5088, 5089, 5093, 5098, 5105, 5106, 5118, 5122,
  5124, 5132, 5133, 5145, 5148, 5153, 5155, 5162, 5165, 5167, 5169, 5175, 5177, 5192, 5193, 5196, 5200, 5206, 5212,
  5215, 5217, 5224, 5225, 5226, 5228, 5233, 5239, 5247, 5256, 5262, 5263, 5276, 5288, 5290, 5296, 5297, 5302, 5309,
  5318, 5323, 5349, 5353, 5354, 5355, 5361, 5369, 5374, 5386, 5390, 5392, 5393, 5399, 5402, 5427, 5430, 5433, 5435,
  5454, 5455, 5459, 5467, 5473, 5475, 5476, 5484, 5491, 5492, 5493, 5494, 5497, 5500, 5501, 5505, 5507, 5509, 5510,
  5514, 5517, 5518, 5528, 5530, 5535, 5538, 5540, 5541, 5546, 5561, 5564, 5565, 5570, 5581, 5584, 5590, 5591, 5615,
  5619, 5635, 5641, 5646, 5664, 5665, 5669, 5675, 5676, 5684, 5691, 5699, 5703, 5707, 5721, 5722, 5743, 5746, 5752,
  5778, 5785, 5787, 5788, 5791, 5800, 5805, 5809, 5812, 5814, 5828, 5834, 5835, 5838, 5852, 5861, 5862, 5865, 5867,
  5868, 5878, 5889, 5890, 5896, 5898, 5912, 5914, 5920, 5921, 5924, 5926, 5941, 5944, 5955, 5961, 5966, 5968, 5970,
  5971, 5974, 5981, 5988, 5989, 5993, 5994, 6006, 6017, 6018, 6019, 6022, 6033, 6034, 6038, 6042, 6043, 6044, 6045,
  6047, 6061, 6069, 6073, 6085, 6090, 6094, 6095, 6096, 6105, 6107, 6108, 6114, 6116, 6118, 6122, 6126, 6130, 6138,
  6141, 6152, 6157, 6158, 6169, 6170, 6171, 6182, 6189, 6194, 1, 17, 18, 24, 27, 30, 32, 34, 36, 46, 71, 78, 87, 888,
  450, 270, 225, 246, 213,
]

export const ZionLionsExplorerIDs = [
  7, 8, 10, 12, 16, 25, 28, 31, 37, 39, 48, 52, 53, 54, 55, 56, 59, 60, 65, 68, 72, 76, 77, 79, 81, 83, 89, 90, 92, 97,
  100, 101, 104, 107, 109, 111, 112, 113, 114, 116, 117, 119, 121, 126, 127, 128, 131, 134, 135, 136, 138, 140, 141,
  142, 144, 148, 149, 150, 151, 153, 154, 160, 163, 164, 167, 169, 172, 174, 179, 184, 185, 189, 190, 195, 197, 199,
  205, 208, 211, 212, 220, 223, 224, 231, 234, 239, 248, 252, 254, 260, 263, 269, 274, 277, 283, 286, 287, 288, 289,
  291, 292, 293, 294, 296, 299, 303, 305, 306, 307, 308, 309, 310, 311, 313, 318, 319, 325, 326, 328, 331, 332, 336,
  339, 344, 354, 355, 356, 358, 360, 361, 366, 367, 369, 373, 374, 375, 376, 377, 380, 381, 386, 390, 391, 392, 394,
  399, 403, 404, 407, 408, 412, 413, 419, 421, 423, 427, 430, 433, 434, 436, 437, 440, 445, 451, 458, 459, 460, 461,
  465, 467, 471, 474, 476, 477, 479, 488, 490, 491, 492, 500, 505, 506, 517, 518, 521, 529, 534, 535, 537, 538, 539,
  544, 546, 551, 552, 558, 560, 561, 563, 565, 571, 572, 578, 579, 587, 588, 596, 597, 598, 601, 603, 605, 606, 613,
  615, 621, 626, 627, 628, 632, 633, 639, 641, 646, 652, 653, 654, 657, 658, 660, 662, 664, 669, 673, 677, 683, 684,
  687, 688, 689, 694, 696, 699, 704, 708, 710, 713, 714, 715, 717, 721, 722, 731, 732, 737, 740, 741, 742, 743, 745,
  746, 749, 750, 752, 755, 759, 769, 771, 776, 780, 781, 790, 791, 793, 794, 797, 799, 801, 805, 808, 816, 817, 820,
  825, 826, 828, 830, 835, 836, 843, 851, 857, 865, 870, 871, 872, 873, 877, 879, 882, 883, 893, 894, 895, 896, 900,
  902, 910, 913, 922, 926, 932, 934, 935, 936, 938, 943, 944, 946, 949, 951, 954, 958, 961, 964, 965, 966, 968, 979,
  980, 983, 985, 986, 987, 996, 997, 998, 1000, 1007, 1008, 1011, 1012, 1015, 1017, 1026, 1027, 1030, 1032, 1033, 1045,
  1050, 1051, 1053, 1055, 1059, 1063, 1064, 1067, 1068, 1077, 1081, 1084, 1088, 1089, 1093, 1094, 1098, 1099, 1102,
  1104, 1105, 1106, 1110, 1111, 1113, 1116, 1118, 1123, 1124, 1127, 1128, 1132, 1133, 1134, 1135, 1144, 1145, 1149,
  1150, 1153, 1156, 1160, 1161, 1163, 1165, 1167, 1168, 1169, 1174, 1175, 1176, 1177, 1190, 1203, 1204, 1206, 1210,
  1215, 1216, 1219, 1221, 1229, 1234, 1237, 1243, 1247, 1254, 1255, 1261, 1263, 1277, 1278, 1282, 1284, 1285, 1286,
  1287, 1292, 1295, 1298, 1299, 1300, 1304, 1305, 1307, 1308, 1315, 1320, 1321, 1322, 1326, 1329, 1337, 1339, 1340,
  1341, 1346, 1347, 1351, 1352, 1357, 1359, 1362, 1363, 1368, 1371, 1383, 1386, 1390, 1395, 1402, 1405, 1406, 1408,
  1410, 1419, 1421, 1426, 1428, 1430, 1444, 1446, 1447, 1454, 1459, 1460, 1464, 1467, 1468, 1469, 1470, 1471, 1475,
  1476, 1480, 1492, 1494, 1499, 1502, 1503, 1504, 1505, 1507, 1509, 1510, 1511, 1516, 1519, 1523, 1526, 1528, 1531,
  1534, 1535, 1538, 1539, 1540, 1542, 1543, 1544, 1546, 1552, 1554, 1558, 1565, 1569, 1575, 1580, 1595, 1599, 1600,
  1601, 1602, 1605, 1606, 1607, 1608, 1609, 1614, 1615, 1616, 1620, 1622, 1626, 1630, 1635, 1636, 1638, 1641, 1645,
  1646, 1649, 1651, 1653, 1654, 1655, 1657, 1659, 1660, 1663, 1667, 1668, 1669, 1670, 1677, 1680, 1682, 1686, 1687,
  1690, 1691, 1695, 1698, 1703, 1705, 1710, 1711, 1712, 1715, 1716, 1719, 1720, 1723, 1726, 1727, 1736, 1737, 1739,
  1748, 1751, 1754, 1761, 1764, 1767, 1776, 1777, 1778, 1781, 1782, 1787, 1788, 1789, 1794, 1798, 1804, 1806, 1807,
  1810, 1813, 1815, 1817, 1821, 1827, 1828, 1829, 1831, 1834, 1837, 1838, 1844, 1849, 1850, 1854, 1856, 1859, 1868,
  1869, 1871, 1874, 1875, 1876, 1879, 1881, 1883, 1886, 1888, 1891, 1894, 1895, 1898, 1899, 1912, 1918, 1919, 1920,
  1921, 1928, 1929, 1931, 1933, 1947, 1951, 1959, 1960, 1962, 1963, 1966, 1969, 1974, 1977, 1978, 1995, 2004, 2005,
  2006, 2008, 2010, 2011, 2018, 2021, 2032, 2035, 2039, 2040, 2042, 2051, 2057, 2058, 2061, 2062, 2065, 2072, 2074,
  2086, 2089, 2090, 2096, 2101, 2103, 2105, 2109, 2116, 2122, 2126, 2132, 2133, 2134, 2135, 2139, 2144, 2147, 2148,
  2154, 2158, 2165, 2167, 2170, 2172, 2173, 2175, 2177, 2178, 2181, 2186, 2190, 2194, 2195, 2197, 2198, 2199, 2200,
  2205, 2218, 2219, 2226, 2229, 2237, 2241, 2246, 2248, 2250, 2255, 2256, 2259, 2260, 2262, 2272, 2279, 2283, 2284,
  2285, 2286, 2292, 2293, 2295, 2300, 2301, 2306, 2311, 2312, 2320, 2321, 2322, 2325, 2327, 2328, 2329, 2336, 2345,
  2346, 2347, 2349, 2350, 2351, 2356, 2361, 2363, 2364, 2367, 2369, 2371, 2375, 2376, 2377, 2378, 2382, 2383, 2384,
  2385, 2388, 2392, 2396, 2397, 2401, 2405, 2409, 2411, 2414, 2417, 2420, 2425, 2426, 2430, 2433, 2434, 2435, 2438,
  2440, 2441, 2442, 2443, 2451, 2454, 2458, 2461, 2462, 2464, 2468, 2469, 2471, 2472, 2476, 2480, 2486, 2487, 2496,
  2504, 2512, 2516, 2528, 2532, 2533, 2534, 2546, 2553, 2554, 2556, 2557, 2558, 2560, 2561, 2566, 2567, 2571, 2579,
  2581, 2585, 2586, 2589, 2595, 2596, 2599, 2600, 2607, 2611, 2613, 2615, 2623, 2633, 2634, 2644, 2645, 2646, 2648,
  2649, 2654, 2656, 2659, 2660, 2661, 2662, 2666, 2670, 2671, 2674, 2675, 2677, 2680, 2681, 2682, 2688, 2701, 2702,
  2712, 2717, 2718, 2723, 2724, 2727, 2731, 2733, 2734, 2735, 2737, 2738, 2747, 2757, 2759, 2764, 2768, 2770, 2776,
  2780, 2784, 2790, 2798, 2799, 2802, 2804, 2812, 2820, 2823, 2828, 2830, 2837, 2840, 2841, 2844, 2845, 2850, 2853,
  2856, 2858, 2864, 2865, 2866, 2868, 2871, 2879, 2882, 2883, 2886, 2887, 2890, 2897, 2903, 2904, 2912, 2913, 2916,
  2918, 2920, 2922, 2924, 2933, 2934, 2936, 2937, 2941, 2944, 2949, 2952, 2955, 2956, 2957, 2960, 2966, 2968, 2973,
  2975, 2977, 2981, 2983, 2989, 2992, 2993, 2994, 2995, 2997, 3014, 3015, 3016, 3017, 3023, 3025, 3029, 3030, 3038,
  3043, 3047, 3059, 3065, 3066, 3068, 3069, 3072, 3074, 3084, 3102, 3107, 3108, 3110, 3111, 3117, 3118, 3119, 3120,
  3121, 3122, 3128, 3132, 3134, 3135, 3136, 3140, 3142, 3144, 3149, 3152, 3153, 3158, 3160, 3163, 3164, 3165, 3167,
  3169, 3170, 3172, 3177, 3178, 3180, 3182, 3186, 3188, 3191, 3197, 3198, 3200, 3213, 3222, 3224, 3226, 3227, 3231,
  3232, 3233, 3235, 3236, 3237, 3238, 3247, 3251, 3253, 3254, 3256, 3262, 3263, 3265, 3270, 3271, 3272, 3274, 3275,
  3277, 3282, 3291, 3295, 3296, 3299, 3302, 3305, 3309, 3310, 3312, 3315, 3316, 3325, 3329, 3330, 3338, 3344, 3351,
  3357, 3359, 3360, 3361, 3368, 3372, 3374, 3375, 3377, 3379, 3380, 3381, 3383, 3384, 3386, 3387, 3388, 3392, 3397,
  3398, 3400, 3416, 3417, 3423, 3424, 3427, 3429, 3430, 3432, 3435, 3444, 3445, 3448, 3449, 3450, 3454, 3457, 3459,
  3466, 3470, 3472, 3475, 3477, 3478, 3480, 3481, 3487, 3494, 3497, 3499, 3500, 3502, 3504, 3508, 3510, 3512, 3513,
  3514, 3518, 3519, 3522, 3524, 3526, 3527, 3528, 3530, 3531, 3534, 3538, 3560, 3561, 3563, 3566, 3567, 3569, 3570,
  3571, 3572, 3575, 3576, 3583, 3584, 3587, 3589, 3591, 3592, 3594, 3599, 3604, 3607, 3608, 3615, 3616, 3617, 3631,
  3633, 3637, 3646, 3648, 3651, 3655, 3658, 3659, 3661, 3664, 3666, 3668, 3669, 3671, 3675, 3677, 3678, 3682, 3683,
  3685, 3687, 3697, 3708, 3709, 3715, 3716, 3718, 3719, 3721, 3722, 3728, 3732, 3739, 3740, 3747, 3749, 3751, 3753,
  3754, 3756, 3758, 3764, 3765, 3767, 3768, 3772, 3773, 3777, 3783, 3787, 3793, 3797, 3799, 3802, 3805, 3806, 3807,
  3816, 3817, 3819, 3820, 3824, 3825, 3827, 3828, 3829, 3830, 3835, 3837, 3840, 3842, 3843, 3844, 3845, 3850, 3851,
  3853, 3856, 3857, 3859, 3861, 3869, 3881, 3886, 3887, 3888, 3896, 3901, 3902, 3904, 3905, 3908, 3911, 3912, 3913,
  3920, 3922, 3923, 3924, 3925, 3933, 3945, 3948, 3957, 3962, 3966, 3978, 3979, 3982, 3988, 3990, 3991, 3993, 3994,
  3998, 4001, 4005, 4006, 4014, 4018, 4019, 4021, 4022, 4025, 4030, 4034, 4036, 4037, 4040, 4042, 4047, 4048, 4051,
  4055, 4061, 4067, 4069, 4070, 4071, 4073, 4079, 4081, 4085, 4087, 4089, 4090, 4091, 4093, 4094, 4096, 4107, 4108,
  4109, 4110, 4111, 4112, 4113, 4115, 4124, 4125, 4126, 4127, 4130, 4133, 4140, 4141, 4146, 4150, 4151, 4154, 4156,
  4158, 4159, 4160, 4162, 4163, 4165, 4169, 4173, 4178, 4182, 4185, 4186, 4189, 4190, 4194, 4195, 4197, 4202, 4207,
  4208, 4210, 4212, 4215, 4217, 4219, 4224, 4227, 4228, 4229, 4230, 4232, 4235, 4236, 4237, 4243, 4247, 4252, 4255,
  4256, 4258, 4260, 4267, 4271, 4274, 4277, 4281, 4284, 4287, 4288, 4292, 4293, 4294, 4300, 4302, 4303, 4304, 4305,
  4306, 4309, 4311, 4315, 4316, 4323, 4329, 4330, 4331, 4333, 4339, 4340, 4341, 4344, 4347, 4351, 4353, 4359, 4360,
  4365, 4366, 4388, 4389, 4391, 4392, 4394, 4396, 4397, 4402, 4404, 4407, 4409, 4410, 4411, 4412, 4413, 4416, 4417,
  4418, 4420, 4421, 4423, 4425, 4428, 4441, 4443, 4447, 4452, 4453, 4455, 4463, 4465, 4467, 4472, 4474, 4475, 4480,
  4481, 4483, 4486, 4491, 4494, 4497, 4498, 4499, 4504, 4509, 4510, 4516, 4518, 4519, 4520, 4528, 4532, 4535, 4536,
  4543, 4546, 4547, 4548, 4554, 4555, 4557, 4561, 4562, 4565, 4566, 4568, 4569, 4571, 4576, 4579, 4583, 4585, 4587,
  4588, 4589, 4590, 4595, 4599, 4601, 4603, 4604, 4606, 4610, 4612, 4613, 4616, 4617, 4621, 4623, 4625, 4627, 4633,
  4636, 4637, 4638, 4641, 4646, 4651, 4652, 4653, 4654, 4658, 4662, 4663, 4665, 4666, 4671, 4673, 4678, 4679, 4680,
  4681, 4684, 4686, 4687, 4688, 4692, 4694, 4695, 4696, 4701, 4702, 4707, 4708, 4709, 4710, 4711, 4713, 4717, 4720,
  4723, 4725, 4731, 4732, 4733, 4734, 4738, 4743, 4745, 4751, 4754, 4755, 4760, 4761, 4762, 4763, 4765, 4766, 4771,
  4774, 4775, 4777, 4778, 4782, 4783, 4785, 4786, 4787, 4790, 4792, 4793, 4797, 4798, 4800, 4802, 4804, 4808, 4809,
  4814, 4816, 4818, 4819, 4824, 4826, 4832, 4833, 4840, 4848, 4850, 4861, 4867, 4871, 4878, 4879, 4882, 4883, 4887,
  4895, 4898, 4900, 4902, 4904, 4907, 4911, 4913, 4918, 4919, 4920, 4921, 4922, 4927, 4929, 4931, 4933, 4938, 4940,
  4945, 4947, 4960, 4965, 4975, 4976, 4977, 4980, 4981, 4984, 4989, 4991, 4994, 4997, 5000, 5002, 5003, 5006, 5007,
  5008, 5010, 5012, 5017, 5018, 5022, 5023, 5024, 5025, 5029, 5039, 5044, 5045, 5049, 5062, 5065, 5066, 5068, 5069,
  5075, 5076, 5077, 5079, 5081, 5083, 5085, 5090, 5094, 5095, 5097, 5101, 5103, 5108, 5109, 5112, 5116, 5117, 5126,
  5140, 5146, 5151, 5154, 5158, 5160, 5168, 5170, 5173, 5174, 5176, 5178, 5179, 5180, 5184, 5186, 5190, 5194, 5197,
  5198, 5201, 5203, 5205, 5210, 5211, 5216, 5221, 5222, 5227, 5230, 5231, 5235, 5236, 5240, 5242, 5244, 5245, 5248,
  5252, 5254, 5255, 5257, 5261, 5265, 5268, 5277, 5278, 5292, 5298, 5301, 5304, 5305, 5313, 5314, 5324, 5326, 5333,
  5334, 5339, 5340, 5341, 5345, 5346, 5347, 5350, 5351, 5362, 5366, 5370, 5371, 5378, 5379, 5383, 5385, 5394, 5395,
  5397, 5401, 5404, 5406, 5408, 5409, 5412, 5416, 5417, 5418, 5421, 5424, 5425, 5431, 5432, 5437, 5438, 5439, 5440,
  5441, 5443, 5444, 5445, 5447, 5448, 5450, 5452, 5458, 5463, 5464, 5470, 5471, 5474, 5477, 5478, 5486, 5488, 5490,
  5495, 5496, 5498, 5499, 5516, 5519, 5520, 5524, 5525, 5526, 5529, 5534, 5539, 5545, 5550, 5553, 5554, 5558, 5560,
  5566, 5568, 5574, 5576, 5577, 5586, 5587, 5588, 5593, 5596, 5598, 5600, 5605, 5607, 5608, 5610, 5613, 5614, 5618,
  5620, 5621, 5624, 5626, 5627, 5630, 5632, 5634, 5638, 5645, 5648, 5650, 5660, 5663, 5667, 5668, 5674, 5681, 5682,
  5683, 5687, 5692, 5693, 5694, 5695, 5700, 5711, 5717, 5719, 5725, 5726, 5728, 5729, 5730, 5732, 5733, 5744, 5748,
  5754, 5755, 5760, 5761, 5764, 5769, 5770, 5771, 5773, 5776, 5777, 5780, 5781, 5789, 5793, 5794, 5796, 5798, 5799,
  5802, 5804, 5811, 5813, 5815, 5816, 5819, 5823, 5825, 5826, 5832, 5836, 5839, 5840, 5843, 5845, 5850, 5851, 5854,
  5855, 5857, 5859, 5866, 5871, 5872, 5874, 5881, 5883, 5884, 5886, 5892, 5893, 5894, 5899, 5905, 5906, 5907, 5909,
  5915, 5919, 5922, 5925, 5930, 5931, 5932, 5936, 5937, 5939, 5946, 5951, 5957, 5963, 5964, 5967, 5973, 5976, 5978,
  5979, 5982, 5983, 5984, 5986, 5992, 5997, 6004, 6015, 6025, 6030, 6040, 6051, 6053, 6055, 6056, 6059, 6062, 6063,
  6066, 6067, 6071, 6074, 6075, 6077, 6079, 6081, 6082, 6086, 6089, 6099, 6101, 6103, 6110, 6111, 6115, 6119, 6124,
  6125, 6137, 6146, 6147, 6149, 6150, 6154, 6161, 6163, 6165, 6167, 6168, 6172, 6173, 6174, 6176, 6180, 6181, 6185,
  6188, 6190, 6191, 6192, 6193, 6198, 1039, 256, 267, 278, 431, 203, 300, 454, 463, 449, 257,
]

export const ZionLionsFarmersIDs = [
  5428, 5603, 2665, 4135, 4794, 2876, 2348, 2357, 2333, 4560, 3782, 2684, 3055, 4045, 5625, 3243, 1842, 4714, 1658,
  3588, 4381, 182, 1967, 1961, 765, 1688, 3786, 3467, 4626, 2636, 904, 2050, 6020, 1349, 14, 3245, 1839, 880, 2159,
  2908, 3058, 3917, 4810, 850, 514, 219, 47, 2807, 1692, 3410, 4273, 2389, 763, 1623, 1227, 1597, 4813, 3, 4234, 3509,
  2958, 3011, 2499, 3695, 4374, 3094, 705, 1313, 543, 1823, 2316, 3595, 3479, 4265, 2969, 2143, 5271, 5763, 827, 1018,
  3970, 5182, 3810, 2696, 890, 351, 2849, 1567, 1922, 3307, 648, 3785, 5556, 4378, 3001, 1847, 1225, 2453, 1366, 1401,
  2360, 2506, 5749, 649, 720, 3650, 5654, 2448, 3129, 3539, 2268, 630, 906, 5929, 315, 1451, 5119, 1396, 819, 1836,
  3626, 1207, 5320, 5422, 1338, 5149, 3507, 4242, 4406, 5306, 6023, 3283, 2026, 5833, 1496, 730, 3606, 222, 2719, 4838,
  1324, 638, 3580, 3609, 4354, 432, 886, 4080, 1484, 5740, 4863, 3367, 2954, 950, 2766, 3564, 5673, 1674, 2485, 4205,
  176, 2142, 855, 1527, 4132, 1934, 3490, 2393, 852, 2206, 583, 4971, 5999, 1075, 1183, 3818, 2359, 3535, 1652, 4139,
  928, 2152, 2781, 6072, 4060, 4730, 6134, 4435, 2539, 1350, 1394, 3578, 2267, 3061, 1266, 5434, 2168, 3779, 5689, 995,
  1627, 1946, 2291, 2714, 5013, 4041, 4851, 337, 1441, 1029, 3746, 2605, 756, 1799, 1249, 3406, 3039, 4414, 4894, 3493,
  1892, 4424, 1584, 2309, 241, 5266, 4737, 110, 2475, 1245, 3846, 2544, 1477, 6065, 3242, 324, 4538, 3752, 1744, 5902,
  5797, 5344, 5114, 5708, 2817, 5604, 3549, 2410, 3147, 4116, 5720, 5030, 2739, 1585, 929, 2765, 5910, 4345, 4529, 5578,
  6003, 2180, 5786, 1115, 2366, 5469, 5940, 1779, 379, 2940, 3581, 4664, 3082, 2146, 5032, 4852, 1563, 602, 3582, 592,
  4811, 4951, 2069, 4120, 5050, 3077, 3127, 3745, 4843, 1438, 3999, 4104, 2810, 4821, 5204, 2939, 557, 3949, 1582, 3636,
  5756, 6054, 4791, 754, 3080, 3041, 2176, 3996, 4596, 5128, 2545, 834, 161, 5487, 4149, 5259, 2263, 2400, 6, 57, 884,
  216, 3252, 3345, 5420, 3126, 1162, 5690, 2423, 5303, 5710, 3241, 2488, 1489, 3838, 3003, 497, 1518, 3355, 4845, 5,
  6046, 2184, 3880, 3266, 5172, 2421, 19, 1628, 1773, 758, 3104, 3098, 5531, 3574, 5129, 1319, 812, 5935, 3788, 5289,
  1465, 481, 5102, 4602, 3276, 5229, 3832, 5972, 2112, 5869, 3175, 4722, 3557, 3868, 5628, 5954, 3085, 312, 867, 5504,
  1885, 2153, 1976, 5380, 3980, 2789, 4487, 2808, 3407, 5783, 3621, 1579, 2002, 2342, 1443, 1905, 4697, 6160, 5150, 23,
  1665, 4449, 1541, 2839, 5143, 4660, 4577, 5723, 1239, 3073, 3556, 2984, 3938, 734, 3292, 1090, 1957, 2927, 4746, 4324,
  2247, 5706, 6197, 4827, 3532, 3159, 2893, 3662, 1061, 774, 1864, 3035, 338, 4675, 5810, 4525, 469, 2592, 1816, 5563,
  577, 1796, 5055, 2577, 3867, 3681, 1472, 6178, 5837, 4092, 1058, 5580, 2948, 3202, 2257, 702, 4473, 4142, 5202, 2025,
  1941, 4611, 5934, 3382, 1034, 3221, 5462, 2056, 989, 2637, 1252, 1056, 2450, 2017, 736, 2047, 620, 3558, 5357, 4924,
  4291, 5307, 2652, 1707, 5790, 4572, 3733, 1411, 6145, 2617, 5885, 5759, 4987, 1904, 3823, 3684, 2093, 511, 5611, 5521,
  5549, 1576, 1211, 5411, 3724, 2343, 5847, 5818, 4897, 2630, 2852, 3433, 1878, 5745, 3408, 1178, 4788, 2193, 2594, 839,
  6128, 4058, 4429, 132, 1593, 783, 5735, 823, 2861, 2515, 5249, 5274, 5567, 4053, 4250, 3334, 810, 6005, 2999, 1975,
  5985, 5870, 1312, 5415, 4387, 3461, 914, 4193, 5315, 3981, 2792, 1557, 3951, 542, 2976, 5977, 3087, 2373, 61, 1031,
  4910, 861, 3652, 4726, 6135, 1065, 1851, 3495, 4398, 3264, 4784, 4923, 2341, 2037, 1925, 1581, 1820, 2588, 2354, 5100,
  3471, 4046, 86, 4756, 3943, 1230, 2942, 540, 3166, 1734, 1634, 5331, 3995, 3103, 1590, 924, 782, 2971, 2427, 849,
  4630, 3663, 1994, 4253, 5617, 994, 1427, 5631, 85, 608, 5536, 522, 5685, 2772, 1387, 5057, 1281, 3150, 2479, 3634,
  5407, 4249, 1672, 2209, 993, 1923, 3203, 3395, 5933, 49, 5372, 5489, 2597, 1536, 773, 2455, 6143, 1253, 5398, 414,
  235, 1257, 963, 4683, 472, 1843, 3313, 3300, 948, 5461, 5311, 2365, 1114, 4877, 4134, 4629, 981, 3533, 1251, 1146,
  1870, 753, 5583, 3854, 4348, 5414, 4659, 2254, 2740, 2077, 4549, 1283, 674, 4342, 1520, 5795, 1574, 3597, 5705, 1992,
  1587, 5208, 1262, 3714, 3928, 3822, 5299, 609, 29, 1481, 5159, 6041, 5911, 272, 4405, 2907, 2491, 3363, 1621, 210,
  1743, 3064, 5827, 509, 2355, 4082, 1194, 6162, 5512, 1384, 1158, 3537, 1280, 5820, 3953, 5938, 6060, 5943, 15, 3679,
  5209, 4859, 4335, 3201, 4415, 4372, 4558, 2896, 2705, 159, 718, 2800, 393, 1968, 5751, 4952, 2073, 912, 4591, 1429,
  617, 2445, 1699, 3885, 4383, 74, 4942, 1200, 2774, 3337, 5821, 3956, 1155, 513, 194, 4495, 3892, 2067, 1028, 5716,
  2169, 889, 1833, 2511, 3573, 6113, 3322, 4873, 4097, 2751, 420, 2415, 2691, 2034, 4431, 2854, 4278, 2669, 115, 4551,
  3673, 5652, 1142, 3774, 2201, 711, 5547, 5697, 3199, 2593, 1483, 4332, 3341, 5368, 2502, 4043, 2138, 4899, 5389, 569,
  4855, 4830, 1336, 3760, 3611, 4117, 960, 3602, 2693, 4064, 5429, 907, 3488, 678, 1927, 4550, 5185, 5875, 5308, 4164,
  4748, 4780, 593, 5423, 4050, 703, 4204, 1380, 4768, 1303, 3155, 2707, 1415, 5727, 1170, 3847, 453, 3836, 5975, 3627,
  789, 925, 1317, 398, 348, 4865, 382, 4367, 1271, 5156, 3240, 352, 1372, 158, 4088, 3324, 2048, 2832, 1971, 51, 3598,
  3156, 2863, 3791, 4891, 856, 5337, 4896, 2243, 4700, 2894, 2339, 1057, 22, 4943, 6100, 4917, 2653, 1049, 5125, 2123,
  4024, 3554, 3812, 1193, 3246, 3402, 5121, 1327, 3020, 1412, 2978, 1450, 162, 2428, 5595, 6049, 659, 1532, 1164, 1198,
  2572, 3115, 4176, 3703, 3795, 6009, 2298, 3501, 2131, 4077, 5571, 3280, 1435, 5199, 2097, 237, 3882, 5052, 2416, 5343,
  139, 4456, 3090, 3192, 3484, 2100, 4699, 2222, 3347, 2678, 2783, 1811, 3328, 1768, 275, 5377, 2549, 364, 599, 3944,
  4296, 43, 1048, 5856, 4600, 5766, 193, 2484, 2788, 3866, 5046, 183, 4379, 2657, 1097, 2043, 1096, 2932, 671, 1422,
  1453, 4634, 5597, 5916, 4138, 9, 3327, 4796, 3288, 5527, 1877, 4759, 2353, 3229, 3781, 1316, 1589, 570, 727, 4837,
  4622, 5082, 67, 2225, 2422, 2711, 1809, 3784, 3294, 1867, 1038, 2001, 26, 3858, 2947, 5801, 1365, 4153, 2374, 5897,
  3729, 3975, 5336, 792, 4007, 2372, 1643, 1840, 1560, 1914, 5657, 3101, 2110, 2078, 3446, 1970, 4573, 3371, 723, 2945,
  4541, 2220, 2045, 697, 5643, 5945, 3965, 6001, 1485, 1250, 218, 2606, 4567, 3455, 625, 5110, 541, 5375, 6000, 2028,
  4598, 1721, 5251, 1762, 3415, 3049, 1199, 576, 5923, 2514, 3401, 5070, 1907, 370, 5258, 1908, 4338, 5502, 1455, 4432,
  5879, 3208, 3244, 4502, 4846, 5913, 611, 2742, 4959, 3874, 4275, 4705, 4876, 3914, 1738, 4540, 3194, 5644, 4427, 2787,
  2911, 4885, 6179, 5104, 3021, 5830, 2299, 2756, 984, 2650, 502, 1044, 5253, 527, 4716, 5542, 3405, 3438, 1353, 612,
  3983, 1935, 271, 2672, 1999, 1853, 3927, 5948, 1890, 1137, 5515, 5403, 3900, 1930, 3418, 3792, 2640, 735, 4905, 5041,
  795, 5956, 2524, 4000, 2785, 1087, 4858, 1872, 4582, 4057, 173, 3635, 2497, 5080, 2398, 1909, 236, 1076, 2526, 1932,
  2721, 4842, 5616, 5294, 3248, 525, 2796, 5757, 635, 5950, 4946, 4003, 4004, 3054, 3952, 4188, 5958, 2857, 1108, 1825,
  3196, 4033, 1042, 4245, 3811, 4506, 4967, 3898, 656, 5775, 2598, 751, 4864, 41, 4890, 330, 5026, 2614, 3376, 5015,
  5718, 2769, 2203, 5841, 614, 1117, 846, 1100, 5572, 4216, 5508, 1361, 4605, 5661, 3304, 4972, 2265, 6097, 4676, 5359,
  5321, 4145, 5310, 1374, 859, 2452, 5327, 2258, 988, 5738, 3710, 4847, 4998, 2230, 1896, 1297, 4853, 6052, 4320, 1588,
  371, 6032, 187, 4789, 1625, 1201, 501, 4869, 4198, 2591, 58, 2775, 1954, 6151, 5073, 5557, 5765, 6186, 6102, 5189,
  1173, 1456, 3921, 5152, 2068, 5537, 1775, 1956, 3306, 4101, 739, 2379, 3723, 4015, 5803, 3239, 2834, 3775, 2624, 1979,
  6136, 512, 1770, 157, 2692, 2744, 6133, 6024, 1420, 3890, 3638, 1054, 1166, 5335, 3701, 3895, 5332, 1611, 642, 5734,
  1259, 586, 3031, 3972, 2686, 1445, 647, 3940, 549, 1818, 3123, 2031, 98, 2628, 2478, 5453, 3220, 470, 2867, 844, 3705,
  6155, 1151, 6127, 5051, 4559, 3696, 4522, 4470, 2387, 1733, 2801, 2938, 2470, 4218, 5680, 4357, 2273, 2323, 2510,
  5187, 3083, 1862, 424, 3801, 3955, 5917, 5283, 4370, 2880, 3109, 6132, 536, 417, 2547, 5238, 766, 655, 3546, 3757,
  3447, 5135, 1369, 2211, 2236, 4299, 192, 3725, 2465, 1661, 5218, 5704, 1493, 6106, 5468, 3301, 2140, 2736, 1953, 5295,
  1086, 1797, 1564, 719, 2107, 3780, 4640, 4386, 3290, 5750, 3062, 6098, 5714, 3547, 346, 3693, 503, 2791, 5503, 2130,
  3738, 4928, 2264, 416, 6028, 1945, 3281, 806, 1756, 4246, 1373, 3385, 4795, 3063, 1289, 4072, 1732, 5952, 3151, 4935,
  1981, 3942, 1080, 2855, 5742, 4803, 5677, 1693, 1202, 5134, 1130, 5647, 564, 2821, 1302, 1345, 4893, 3906, 3268, 2819,
  4123, 3743, 1901, 5533, 5400, 1208, 5633, 3712, 2587, 589, 1159, 6084, 1500, 1915, 3358, 2091, 2745, 5047, 976, 4964,
  4949, 5001, 2698, 426, 2943, 3688, 2683, 2877, 1224, 5037, 4313, 5758, 3171, 1407, 2833, 1074, 3553, 1391, 2157, 3872,
  3907, 1121, 945, 5322, 4328, 1955, 1082, 5887, 4369, 5373, 840, 3162, 531, 2294, 1551, 2027, 930, 3876, 362, 1021,
  1521, 4031, 4478, 2716, 4179, 5460, 4533, 5544, 5219, 5330, 1258, 5234, 5419, 1179, 1973, 6050, 2603, 3289, 4301,
  5291, 5995, 6117, 2982, 4575, 5953, 3440, 2921, 1548, 2166, 4440, 3778, 604, 2119, 3321, 2408, 4285, 2639, 145, 724,
  396, 4874, 5552, 5792, 5612, 6068, 5822, 2638, 4735, 5451, 1013, 4903, 3894, 2305, 5908, 372, 5651, 2815, 1416, 5092,
  3623, 5637, 1913, 3389, 2730, 3974, 1591, 1092, 3849, 4966, 1631, 5466, 5078, 4400, 4534, 429, 1004, 5020, 5054, 2746,
  5807, 4820, 1987, 4131, 5709, 4925, 3645, 595, 3287, 2816, 2959, 832, 5166, 3809, 1650, 3551, 4078, 5860, 1457, 4542,
  1846, 672, 2664, 1729, 5569, 1529, 2145, 3211, 1187, 3468, 3968, 3946, 143, 2335, 6129, 6029, 4592, 3720, 1414, 5873,
  6013, 4916, 3441, 3223, 885, 1725, 152, 4614, 281, 2, 941, 3804, 1112, 4469, 2809, 4361, 2518, 3340, 1742, 2274, 2224,
  744, 842, 2494, 4063, 1010, 3473, 2892, 4, 4742, 4968, 4647, 4322, 5064, 1989, 1270, 1001, 2212, 3496, 4839, 6039,
  1240, 1771, 4026, 5293, 833, 5163, 4166, 2805, 5356, 6076, 2261, 2602, 5670, 3079, 1378, 2695, 4970, 3249, 2522, 4434,
  1214, 6139, 2998, 4144, 3776, 4233, 3987, 468, 953, 3409, 970, 4936, 2182, 1618, 3707, 5702, 5753, 3630, 1545, 198,
  2709, 44, 2217, 4264, 5824, 1880, 2337, 4161, 3086, 2049, 2352, 3815, 1152, 1916, 5111, 2070, 6012, 4990, 4272, 1073,
  4862, 33, 5891, 3362, 3323, 2509, 4401, 4147, 3699, 1461, 3973, 5352, 3483, 301, 4825, 2277, 3356, 5338, 3676, 1181,
  800, 84, 1697, 5962, 1487, 1561, 1573, 2590, 3353, 242, 2729, 1861, 4065, 5363, 3349, 4807, 2888, 5579, 2404, 2750,
  35, 891, 815, 3370, 679, 2803, 329, 4884, 240, 2297, 5084, 276, 2319, 4609, 2754, 1694, 2679, 3436, 4049, 4458, 3698,
  5275, 4728, 1666, 2183, 2580, 3343, 2831, 4776, 395, 3731, 4168, 1702, 4757, 748, 5659, 6021, 2859, 4382, 2227, 4500,
  1632, 4114, 2252, 1938, 5410, 2985, 1730, 1598, 487, 5522, 5191, 2986, 2906, 520, 3540, 5928, 4620, 3420, 2732, 4390,
  4729, 876, 3112, 1184, 302, 6112, 6131, 2390, 1172, 5387, 284, 3012, 6007, 171, 5655, 1857, 5959, 5142, 3331, 4999,
  5096, 5220, 1333, 5384, 406, 634, 5772, 768, 1367, 3672, 2424, 3076, 3625, 3486, 3647, 2962, 1306, 4012, 2537, 1354,
  4221, 3586, 2521, 2075, 1005, 2619, 2632, 1197, 3365, 957, 2431, 1812, 1559, 567, 2573, 2836, 818, 5053, 1571, 5698,
  6037, 4909, 5091, 5130, 2174, 1983, 5559, 4298, 3629, 3997, 1083, 4248, 1644, 2610, 594, 3019, 1393, 4744, 510, 5086,
  1675, 1060, 6104, 5161, 4446, 1865, 2726, 2459, 2842, 3961, 1678, 837, 2340, 480, 6027, 2846, 1772, 2884, 6092, 3950,
  4661, 2505, 4593, 4752, 170, 1612, 6093, 5960, 4312, 3585, 230, 5067, 5317, 5903, 5965, 5895, 4029, 4488, 3936, 5636,
  2164, 2928, 4457, 5848, 3390, 3210, 1758, 5671, 4286, 1382, 347, 6199, 4318, 2761, 971, 5784, 1342, 5364, 716, 4607,
  2715, 972, 3133, 4552, 3848, 323, 38, 5715, 5358, 4628, 2429, 2926, 5136, 5138, 1377, 3826, 5594, 1003, 1696, 5601,
  2432, 2584, 3929, 1679, 1213, 3800, 409, 1988, 5034, 5141, 4279, 5927, 5157, 822, 507, 1802, 3460, 5741, 5264, 285,
  4290, 4393, 4831, 2439, 91, 2162, 2873, 4503, 874, 4822, 4505, 3399, 3046, 5548, 137, 1791, 2403, 663, 397, 6183,
  3422, 4881, 4213, 221, 2437, 5606, 5774, 401, 3689, 2728, 6159, 3520, 1035, 118, 2088, 4121, 869, 3097, 3529, 5656,
  5829, 2635, 2483, 5672, 4211, 3000, 5998, 4039, 676, 5686, 2289, 5888, 3875, 4674, 2095, 280, 4023, 4451, 1101, 2331,
  5214, 528, 6148, 3674, 5312, 1860, 5679, 1940, 3026, 2946, 2493, 1547, 779, 1570, 863, 4270, 2915, 4319, 4823, 644,
  5696, 1192, 5181, 3318, 5653, 1982, 4650, 2019, 1205, 1985, 1107, 4892, 3704, 1268, 5061, 2304, 5144, 4137, 5629, 422,
  3954, 4801, 3964, 1043, 1648, 4703, 675, 4517, 2862, 4350, 3931, 2282, 821, 50, 3734, 1370, 4325, 6184, 2951, 3027,
  1619, 2891, 622, 5947, 1939, 5449, 3145, 415, 4995, 5250, 624, 2407, 1488, 4781, 4718, 1335, 146, 3759, 1855, 3926,
  2869, 4459, 1478, 3352, 5056, 5842, 6064, 1624, 5446, 4027, 2829, 5260, 4100, 4544, 1610, 4148, 3378, 3841, 693, 5532,
  5456, 4035, 2935, 4939, 273, 4479, 3613, 2466, 4280, 807, 5019, 4689, 5662, 4066, 4511, 772, 3183, 4539, 95, 5195,
  2344, 2762, 2150, 2402, 4643, 533, 5642, 341, 1078, 5901, 3618, 803, 175, 3143, 5482, 5246, 5573, 3195, 4508, 2914,
  760, 1241, 2136, 2773, 3419, 340, 3088, 69, 640, 550, 4693, 788, 2741, 70, 1328, 200, 5767, 5284, 1323, 2271, 1129,
  3737, 3138, 4668, 4908, 680, 4444, 4712, 690, 903, 1583, 4157, 1223, 5269, 6144, 3963, 3269, 5115, 2155, 2191, 4715,
  3976, 757, 5980, 3643, 4719, 3735, 2498, 1397, 5623, 207, 435, 4106, 4570, 4201, 186, 2797, 1845, 4222, 5844, 4635,
  1746, 3106, 3769, 2270, 3989, 2965, 1442, 4352, 974, 123, 3680, 385, 2604, 3125, 1348, 4466, 2527, 4670, 504, 2964,
  6080, 1858, 5040, 2697, 2720, 5712, 5348, 320, 555, 2972, 1553, 610, 5688, 1066, 5900, 4889, 3690, 4979, 3225, 4399,
  4753, 709, 3870, 62, 1562, 1385, 5365, 1272, 1917, 1745, 5232, 5223, 3037, 3314, 5582, 3050, 1126, 103, 645, 5035,
  858, 2974, 4608, 5281, 1685, 5113, 4677, 4074, 2548, 2568, 2124, 321, 5485, 5846, 4484, 1755, 3230, 4806, 3032, 733,
  3168, 2905, 1822, 3005, 5858, 2085, 4829, 4336, 389, 2988, 5737, 3173, 4314, 5481, 3028, 516, 921, 6187, 911, 4805,
  3590, 878, 3185, 20, 2779, 1550, 2555, 3985, 1309, 2760, 917, 1786, 1524, 3492, 2118, 1355, 2128, 4118, 3665, 2156,
  1479, 2160, 483, 4384, 2523, 1783, 1180, 1826, 1993, 4086, 4524, 2221, 5713, 778, 3333, 327, 3469, 1902, 4957, 4747,
  4644, 1724, 1437, 1793, 5436, 1440, 3260, 378, 2996, 6109, 1236, 2758, 5876, 4008, 4477, 6087, 365, 1808, 5273, 4860,
  2179, 45, 5639, 1356, 3326, 5880, 5806, 343, 66, 350, 489, 3255, 6153, 4937, 1830, 2704, 21, 927, 1884, 881, 4526,
  4364, 5107, 787, 3451, 4128, 1293, 1047, 5391, 238, 4448, 898, 314, 1458, 5237, 4174, 1747, 2651, 2013, 4010, 4685,
  5863, 5267, 125, 2901, 5808, 1404, 2917, 4856, 6156, 2232, 2542, 1432, 5171, 2370, 5376, 384, 5147, 2970, 1873, 1774,
  2885, 6026, 3051, 4691, 5241, 5853, 6200, 206, 6011, 4468, 3657, 3600, 5329, 3542, 2141, 3335, 643, 559, 4530, 1381,
  2929, 2851, 5059, 5479, 607, 969, 4584, 6166, 4075, 5506, 5071, 6035, 3279, 3285, 5367, 3317, 2641, 1749, 2963, 2687,
  2296, 1795, 2087, 2033, 2569, 4523, 6196, 1037, 2621, 1936, 2030, 2489, 4988, 562, 3036, 2315, 5123, 1332, 6036, 5014,
  5164, 4545, 4180, 962, 919, 2647, 1235, 1766, 5328, 1486, 5523, 4262, 3452, 3489, 1819, 999, 13, 573, 4368, 4241,
  3565, 1188, 473, 5127, 2919, 5388, 2843, 2187, 3426, 4464, 585, 1980, 4363, 1750, 232, 129, 3700, 3893, 6195, 4769,
  2794, 5747, 3910, 2827, 3960, 6010, 1824, 5483, 2658, 2234, 353, 5996, 6083, 692, 2583, 1185, 3078, 130, 5319, 4395,
  3612, 4563, 947, 1423, 2961, 1275, 6120, 2024, 5599, 99, 2616, 3750, 1070, 2767, 334, 4482, 1735, 3022, 1449, 6002,
  1700, 3394, 3297, 5183, 5991, 2722, 3216, 5300, 2990, 4698, 5042, 5005, 3730, 523, 3002, 2215, 3042, 1731, 923, 5004,
  3860, 2269, 3506, 6164, 681, 770, 2612, 897, 1512, 666, 5282, 4422, 942, 5649, 5849, 4187, 5405, 5027, 5382, 3660,
  165, 5724, 5270, 316, 2266, 1497, 2204, 4346, 5602, 388, 5609, 811, 5031, 5942, 2413, 3577, 2044, 4750, 5585, 5739,
  4017, 4912, 229, 5918, 5457, 3075, 3742, 1673, 1592, 685, 2228, 3434, 5987, 1718, 2860, 916, 3214, 3971, 5543, 1148,
  2208, 2210, 363, 5511, 4136, 1501, 3877, 1984, 3550, 5640, 5011, 3543, 6078, 3228, 3116, 1490, 5286, 3548, 575, 2163,
  2755, 4564, 2436, 5207, 2626, 1943, 3596, 1525, 4941, 5768, 2881, 4615, 2818, 2531, 3761, 901, 4119, 3632, 4143, 2242,
  2535, 2064, 920, 5817, 2474, 2625, 5658, 322, 4772, 875, 2778, 4203, 42, 2290, 691, 629, 5731, 2016, 1676, 3771, 3181,
  133, 5779, 918, 1334, 3465, 1706, 3234, 2041, 4901, 3096, 3852, 3209, 2233, 3336, 209, 2550, 848, 5736, 1364, 3146,
  1119, 1473, 545, 1294, 1020, 4206, 5342, 5139, 5272, 6070, 2513, 661, 4531, 1331, 4016, 2099, 4209, 5678, 2743, 1656,
  4996, 3482, 357, 1218, 75, 2332, 3642, 5551, 3179, 1267, 4594, 3871, 6057, 1288, 2094, 4192, 2106, 5562, 3930, 3284,
  2752, 5555, 2380, 3552, 3411, 547, 5442, 796, 5036, 3319, 5762, 838, 4841, 4122, 747, 5990, 4220, 5099, 726, 4642,
  5622, 5043, 1642, 6048, 2117, 80, 2358, 1763, 5465, 3918, 1025, 63, 2953, 11, 5058, 2931, 1379, 2910, 6008, 4476,
  2362, 3207, 4736, 590, 515, 2847, 2620, 5009, 3189, 582, 2111, 5426, 2520, 6091, 4492, 4183, 2771, 1041, 3013, 6031,
  2481, 2022, 228, 4225, 3308, 5480, 2562, 2543, 5831, 1264, 3879, 5120, 1291, 6140, 804, 5575, 5021, 4083, 1803, 4254,
  4251, 3113, 3702, 854, 5287, 1801, 5213, 6014, 3404, 1662, 493, 5513, 1189, 6058, 5316, 5701, 4836, 5131, 553, 2326,
  486, 40, 1741, 6016, 2872, 3437, 3498, 991, 425, 5381, 764, 3257, 1274, 64, 1814, 4956, 4556, 2576, 956, 1709, 3346,
  584, 3413, 3033, 495, 2052, 3060, 1613, 1157, 4888, 3476, 168, 6123, 2467, 3089, 5243, 4849, 4815, 5904, 3656, 5280,
  2565, 5285, 1256, 4184, 2053, 295, 5969, 1893, 1949, 1805, 2786, 1141, 73, 1418, 1143, 5782, 5137, 5666, 892, 5188,
  201, 202, 96, 317, 266, 233, 457, 456, 243, 244, 245, 455, 247, 249, 250, 251, 253, 255, 258, 259, 261, 262, 264, 438,
  439, 441, 442, 443, 444, 446, 447, 448, 1040,
]

export const BlockedNfts = [
  // 450, 270, 225, 246, 213,
  'builders',
  // 1039, 256, 267, 278, 431, 203, 300, 454, 463, 449, 257,
  'explorers',
  // 201, 202, 96, 317, 266, 233, 457, 456, 243, 244, 245, 455, 247, 249, 250, 251, 253, 255, 258, 259, 261, 262, 264, 438,
  // 439, 441, 442, 443, 444, 446, 447, 448, 1040,
  'farmers',
  82,
  265,
]

export const BlockAccounts = [
  // '0x8F27Bd58160eF4fcfdd1cAD57D6328E96dBC96fa',
  '0xC8f1cC9A9eB050f2AD8B1716184D7dC777633890',
  '0xf40981115123CE0f532C4b97D525901D8961afbb',
  '0x6f8e26859589774de8A586fc86BE7188450B03b5',
  '0x9221A74f31BDCB02F2e59Faad17ed7Da04B716CE',
  '0x98Ac9E18A96946Ea1B2301b3F08fAc7f61E0ffE7',
  '0x3d39522a00616664Cd4Eb00E112d5fF3b01f58eb',
  '0x37bD35177D73c8e12Ce67d0F1f328980234fEFf8',
  '0xEF45F2a46E4Afa2AC1362A527549D254D9959b33',
]
export default nftPools
